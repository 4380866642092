<template>
  <v-form ref="form">
    <v-row>
      <v-col class="mt-0 pb-0" cols="12">
        <v-card>
          <v-card-actions>
          <v-icon @click="close">mdi-arrow-left-circle</v-icon>
           
            <v-spacer></v-spacer>
            Week ending: 
            <date-picker
              v-model="dateString"
              v-validate="'required'"
              data-vv-as="dateString"
              prepend-icon="mdi-calendar"
              name="dateString"
              @input="dateChanged"
              :solo="false"
              :hint="sunday.format('dddd DD MMMM YYYY') + ' - ' + weekEndDate.format('dddd DD MMMM YYYY')"
            ></date-picker>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
            <div v-for="ts in items" :key="ts.id"  class="px-4 pt-4">
                <div class="title">{{ts.user}} - {{ts.status}}</div>
                <table style="font-size:.9em;" class="px-4">
                    <thead>
                    <tr class="font-weight-bold">
                        <td style="width:600px">Item</td>
                       
                        <td class="text-right" >Hours</td>
                    </tr>
                    </thead>
                    <tr v-for="(item,index) in ts.items"  :key="index">
                        <td >{{item.task}}</td>
                  
                        <td class="text-right">{{item.qty.toFixed(2)}}</td>
                    </tr>
                    <tr class="font-weight-bold">
                        <td>Total hours</td>
                        <td class="text-right">{{ts.itemTotal.toFixed(2)}}</td>
                    </tr>
                </table>
                
                <v-divider class="pt-4"></v-divider>
            </div>

          
            
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as timesheetService from "../../services/timesheetService";

import DatePicker from "../../components/core/DatePicker";
Vue.use(VeeValidate);
import moment from "moment";
export default {
  name: "notes",
  components: {
    DatePicker
  },
  data: () => ({
    statuses: ["Draft", "Submitted", "Approved", "Rejected"],
    editedIndex: -1,

    weekEndDate: moment(new Date()),
    dateString: "",
    vm: { items: [] },
    items: [],
    status: "Submitted",
    events: [],
    sunday: moment(new Date()),
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ]
  }),

  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },

    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },

    init() {
      this.vm = {
        items: [],
        status: "Draft"
      };

      this.sunday = moment(this.getSunday(new Date()));
      this.weekEndDate = moment(this.sunday.toDate()).add(6, "days");
      this.dateString = this.weekEndDate.format("YYYY-MM-DD");
      this.getTimesheets();
    },
    dateChanged(a) {
      if(a!==undefined && a.length>0){
      this.sunday = moment(this.getSunday(new Date(a)));
      this.weekEndDate = moment(this.sunday.toDate()).add(6, "days");
      this.dateString = this.weekEndDate.format("YYYY-MM-DD");
      this.getTimesheets();
      }
    },

    getTimesheets() {
      const me = this;
      this.$store.dispatch("setWeekEndDate", me.weekEndDate);
      me.items.splice(0, me.items.length);
      timesheetService
        .listByUserIdWeekEnding(
          this.currentOrganisation.id,
          this.user.id,
          this.weekEndDate.format("YYYY-MM-DD")
        )
        .then(result => {
          if (result.size > 0) {
             
             
            result.docs.map(doc => {
                const ts = doc.data();
                ts.id = doc.id;
                me.items.push(ts);
                let newResult =[]
                ts.items.reduce((res, value)=>{
                    console.log(res)
                    console.log(value)
                   // let duration = me.getDuration(value)
                    // res[value.task]? res[value.task] += duration : res[value.task] = duration;
                    // return res;
                    if (!res[value.task]) {
                        res[value.task] = { task: value.task, qty: 0 };
                        newResult.push(res[value.task])
                    }
                    res[value.task].qty += me.getDuration(value);
                    return res;
                },{})

               let newResultArray =[]
              for(var propt in newResult){
                newResultArray.push(newResult[propt]);
                }
                ts.items=newResultArray;
                let total =0;
                ts.items.forEach((item) =>total+=item.qty);
                ts.itemTotal = total;
              // me.$forceUpdate();
            });
           
          }
        });
    },
    getDuration(vm) {
      if (
        vm.date !== undefined &&
        vm.start != undefined &&
        vm.end != undefined
      ) {
        let startDt = moment(
          new Date(
             vm.date.replace(/-/g, "/") + " " + vm.start
          )
        );
        let endDt = moment(
          new Date(
             vm.date.replace(/-/g, "/") + " " + vm.end
          )
        );
        let duration = moment.duration(endDt.diff(startDt)).asHours();

        return  parseFloat(+duration);
      }
    },
   

    getSunday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day;
      return new Date(d.setDate(diff));
    },

    close() {
      this.$router.push("/timesheets");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
  }
};
</script>
