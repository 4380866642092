import Vue from "vue";

function getRef(orgId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("timesheets");
}

export async function getById(orgId, id) {
  return getRef(orgId)
    .doc(id)
    .get();
}

export async function list(orgId) {
  return getRef(orgId).get();
}

export async function listByUserIdWeekEnding(orgId, userId, weekEnding) {
  return getRef(orgId)
    .where("userId", "==", userId)
    .where("weekEndDate", "==", weekEnding)
    .get();
}
export async function listByWeekEnding(orgId, weekEnding) {
  return getRef(orgId)
    .where("weekEndDate", "==", weekEnding)
    .get();
}

export async function listByStatusWeekEnding(orgId, status, weekEnding) {
  return getRef(orgId)
    .where("status", "==", status)
    .where("weekEndDate", "==", weekEnding)
    .get();
}
export async function save(orgId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId).add(obj);
  }
}
